/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { AuthContext } from "../../contexts/AuthContext";
import { Dropdown, DropdownButton, Form, Button, Modal, Col, } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import es from "date-fns/locale/es";
import weekday from 'dayjs/plugin/weekday';
import getDay from "date-fns/getDay";
import "react-datepicker/dist/react-datepicker.css";
import "../../index.css";
import "./Apointment.css";

registerLocale("es", es);
dayjs.extend(weekday)

function Apointment() {
  const { user1, isAuth } = useContext(AuthContext);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const USRGET = `${process.env.REACT_APP_API}user/${user1.id}`;
  const DOCGET = `${process.env.REACT_APP_API}doctors/${user1.id}`;
  const [schedule, setSchedule] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [formulario, setFormulario] = useState();
  const [pacienteExistente, setPacienteExistente] = useState(false);
  const [primeraCita, setPrimeraCita] = useState(true);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState("Hora");
  const [note, setNote] = useState("Escribe aqui algun comentario a tu turno");
  const [user] = useState(user1.id);
  const [doctor, setDoctor] = useState("");
  const [doctorName, setDoctorName] = useState("Escoge");
  const [doctorLname, setDoctorLname] = useState("Doctor");
  const [usrdates, setUsrdates] = useState([]);
  const [citasTotalesUser, setCitasTotalesUser] = useState([]);
  const [usdat, setUsdat] = useState([]);
  const [usdat2, setUsdat2] = useState([]);
  const [usdat3, setUsdat3] = useState([]);
  const [startDate, setStartDate] = useState();
  const [fecha, setFecha] = useState("");
  const [data, setData] = useState([]);
  const [horasDisponiblesInicio, setHorasDisponiblesInicio] = useState(["09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45", "18:00"]);
  const [daysOff, setDaysOff] = useState([]);
  const [agendaOff, setAgendaOff] = useState([]);
  const [horasOff, setHorasOff] = useState([]);
  const [blockHours, setBlockHours] = useState([]);
  const [borbot, setBorbot] = useState([]);
  const [apa, setApa] = useState("btn btn-info  apagado");
  const [sinHoras, setSinHoras] = useState(false);
  const [limiteCitasObraSemanal, setLimiteCitasObraSemanal] = useState();
  const titleedbot = `${doctorName} ${doctorLname}`;
  const excludeColumns = ["_id", "is_active", "createdAt", "updatedAt"]; // excluye datos del arreglo del filtro
  const SCHDOCGET = `${process.env.REACT_APP_API}schedulesbydoctor/${user1.id}/${doctor}`;
  const SCHUSRGET = `${process.env.REACT_APP_API}schedulesbyuser/${user1.id}/${user1.id}`;
  const SCHUDULEPOST = `${process.env.REACT_APP_API}schedule/${user1.id}`;
  const [show, setShow] = useState(false);
  const [feriados, setFeriados] = useState([])
  const [loading, setLoading] = useState(false);
  let citasTodas = []
  let cantidadCitasPorDia = {}
  let horasSoloViejos = ["09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00"]
  let horaSoloNuevos = ["09:15", "09:45", "10:15", "10:45", "11:15", "11:45", "12:15", "12:45", "13:15", "13:45", "14:15", "14:45", "15:15", "15:45", "16:15", "16:45", "17:15", "17:45"]
  let edadPaciente = dayjs().diff(dayjs(user1.fechaNacimiento), 'year')
  let resultadoHorasTodas = horasDisponiblesInicio.filter((item) => !blockHours.includes(item)).sort();
  let resultadoHorasPacientesViejos = resultadoHorasTodas.filter(function (hora) { return (horaSoloNuevos.indexOf(hora) == -1 ? true : false) })
  let resultadoHorasPacientesNuevos = resultadoHorasTodas.filter(function (hora) { return (horasSoloViejos.indexOf(hora) == -1 ? true : false) })
  let resultadoFinalDiasBloqueados = [...usdat, ...usdat2, ...feriados, ...usdat3]
  let [highlightWithRanges, setHighlightWithRanges] = useState([
    { "react-datepicker__day--highlighted-custom-2": [], },
    { "react-datepicker__day--highlighted-custom-1": [], },
  ]);
  let jsonSend = {
    date,
    profesional: doctor,
    note,
    time,
    type: true,
    user,
    primeraVez: !user1.pacienteExistente ? true : false,
  };

  useEffect(() => {
    axios
      .get(SCHUSRGET, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        setUsrdates(data.data)
        if (data.data.length > 0) {
          if (checkCitasDoctor(data.data, data.data[data.data.length - 1].profesional[0]._id) < data.data[data.data.length - 1].profesional[0].limiteCitasPaciente) {
            // setDoctor(data.data[data.data.length - 1].profesional[0]._id);
            // setDoctorName(data.data[data.data.length - 1].profesional[0].nombre);
            // setDoctorLname(data.data[data.data.length - 1].profesional[0].apellido);
            setTime("Hora");
            setDate(null);
            setStartDate();
            setAgendaOff(data.data[data.data.length - 1].profesional[0].agendaOff);
            setLimiteCitasObraSemanal(data.data[data.data.length - 1].profesional[0].obraSocialAtendida.filter((o) => o.nombre == user1.obraSocial)[0].limite)
          }
        }
      })
      .catch((err) => console.log(err));

    axios
      .get(USRGET, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => { setFormulario(data.data.formulario); setPacienteExistente(data.data.pacienteExistente); setPrimeraCita(data.data.primeraCita) })
      .catch((err) => console.log(err));

    axios.get(`${process.env.REACT_APP_API}feriados`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
      .then((data) => {

        let temp = []

        data.data[0].fechas.forEach((fecha) => { temp.push(dayjs(fecha).$d) })

        setFeriados(temp)

      })
      .catch((err) => console.log(err));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    verify();
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    axios
      .get(DOCGET, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        let temp = [];
        let num = 0;
        for (let i in data.data) {
          if (data.data[i].obraSocialAtendida.some((i) => i.nombre == user1.obraSocial)) {
            if (checkCitasDoctor(usrdates, data.data[i]._id) < data.data[i].limiteCitasPaciente) {
              temp.push(data.data[i]);
            }
          }
          num++;
          if (num == data.data.length) {
            setDoctors(temp);
          }
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [usrdates, schedule]);

  useEffect(() => {
    if (doctor !== "") {
      setUsdat2([])
      axios.get(SCHDOCGET, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
        .then((data) => {
          setSchedule(data.data)
          let temp = []
          let temp2 = []
          let num = 0;
          for (let i in data.data) {
            if (dayjs(`${data.data[i].date.split('T')[0]}${data.data[i].time}`) > dayjs().set('hour', 23).set('minute', 59).set('second', 59)) {
              if (data.data[i].user[0].obraSocial == user1.obraSocial) {
                temp.push(dayjs(data.data[i].date).weekday(0).format('YYYY/MM/DD'))
              }
            }
            num++;
            if (num == data.data.length) {
              var cuentaCitas = {};
              temp.forEach(e => cuentaCitas[e] = cuentaCitas[e] ? cuentaCitas[e] + 1 : 1);
              var resultado = Object.keys(cuentaCitas).map(e => { return { semana: e, citas: cuentaCitas[e] } });
              for (let i2 in resultado) {
                if (resultado[i2].citas >= Number(limiteCitasObraSemanal)) {
                  for (var i3 = 0; i3 <= 6; i3++) {
                    temp2.push(dayjs(resultado[i2].semana).add(i3, 'day').$d)
                  }
                  setUsdat2(temp2)
                }
              }
            }
          }
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [doctor]);

  useEffect(() => {
    let temp = []
    agendaOff.map((dia, i) => {
      if (dia.domingo) {
        temp.push(0)
      }
      if (dia.lunes) {
        temp.push(1)
      }
      if (dia.martes) {
        temp.push(2)
      }
      if (dia.miercoles) {
        temp.push(3)
      }
      if (dia.jueves) {
        temp.push(4)
      }
      if (dia.viernes) {
        temp.push(5)
      }
      if (dia.sabado) {
        temp.push(6)
      }
    })
    setDaysOff(temp)
  }, [agendaOff])

  useEffect(() => {
    udates();
    let temp = []
    if (usrdates.length > 0) {
      for (let i in usrdates) {
        if (new Date(usrdates[i].date).valueOf() > Date.now())
          temp.push(new Date(usrdates[i].date))
      }
      setCitasTotalesUser(temp.length)
    }
    // eslint-disable-next-line
  }, [usrdates]);

  useEffect(() => {

    let horasDisponiblesDeAgendaPacientesViejos
    let horasDisponiblesDeAgendaPacientesNuevos
    let citasReales = schedule
    let temporalUsdat = []
    let horasNoMostrar = []

    if (citasReales.length > 0) {

      let temp = [{ "react-datepicker__day--highlighted-custom-2": [], }, { "react-datepicker__day--highlighted-custom-1": [], },]

      for (let i in citasReales) {
        horasNoMostrar.push({ fecha: citasReales[i].date.split('T')[0], hora: citasReales[i].time })
      }

      for (let i in citasReales) {
        citasTodas.push(citasReales[i].date.split('T')[0])
      }

      citasTodas.forEach(cita => { cantidadCitasPorDia[cita] = cantidadCitasPorDia[cita] + 1 || 1 })

      Object.keys(cantidadCitasPorDia).forEach(fecha => {

        horasDisponiblesDeAgendaPacientesViejos = resultadoHorasPacientesViejos
        horasDisponiblesDeAgendaPacientesNuevos = resultadoHorasPacientesNuevos

        if (pacienteExistente && primeraCita) {
          if (horasOff.length > 0) {
            if (horasDisponiblesDeAgendaPacientesViejos.filter((hora) => !horasOff[dayjs(fecha).$W].horasOff.includes(hora)).every(horaDisponible => horasNoMostrar.filter((hora) => hora.fecha == fecha).map((hora) => hora.hora).includes(horaDisponible))) {
              // console.log('dia lleno', fecha)
              temporalUsdat.push(new Date(dayjs(fecha)))
            }
          }
          if (cantidadCitasPorDia[fecha] > horasDisponiblesDeAgendaPacientesViejos.length * .3 && cantidadCitasPorDia[fecha] < horasDisponiblesDeAgendaPacientesViejos.length * .8) {
            // console.log('dia medio lleno', fecha)
            temp[0]["react-datepicker__day--highlighted-custom-2"].push(dayjs(fecha).$d)
          }
          if (cantidadCitasPorDia[fecha] > horasDisponiblesDeAgendaPacientesViejos.length * .8 && cantidadCitasPorDia[fecha] < horasDisponiblesDeAgendaPacientesViejos.length * .9) {
            // console.log('dia casi lleno', fecha)
            temp[1]["react-datepicker__day--highlighted-custom-1"].push(dayjs(fecha).$d)
          }
        } else {
          if (horasOff.length > 0) {
            if (horasDisponiblesDeAgendaPacientesNuevos.filter((hora) => !horasOff[dayjs(fecha).$W].horasOff.includes(hora)).every(horaDisponible => horasNoMostrar.filter((hora) => hora.fecha == fecha).map((hora) => hora.hora).includes(horaDisponible))) {
              // console.log('dia lleno paciente nuevo', fecha)
              temporalUsdat.push(new Date(dayjs(fecha)))
            }
          }
          if (cantidadCitasPorDia[fecha] > horasDisponiblesDeAgendaPacientesNuevos.length * .5 && cantidadCitasPorDia[fecha] < horasDisponiblesDeAgendaPacientesNuevos.length * .8) {
            // console.log('dia medio lleno paciente nuevo', fecha)
            temp[0]["react-datepicker__day--highlighted-custom-2"].push(dayjs(fecha).$d)
          }
          if (cantidadCitasPorDia[fecha] > horasDisponiblesDeAgendaPacientesNuevos.length * .8 && cantidadCitasPorDia[fecha] < horasDisponiblesDeAgendaPacientesNuevos.length * .9) {
            // console.log('dia casi lleno paciente nuevo', fecha)
            temp[1]["react-datepicker__day--highlighted-custom-1"].push(dayjs(fecha).$d)
          }
        }
      });

      setUsdat3(temporalUsdat)
      setHighlightWithRanges(temp)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [schedule]);

  const diaSeleccionado = (selectedDay) => {
    if (dayjs(selectedDay).$W == 0) {
      setBlockHours(agendaOff[0].horasOff)
    }
    if (dayjs(selectedDay).$W == 1) {
      setBlockHours(agendaOff[1].horasOff)
    }
    if (dayjs(selectedDay).$W == 2) {
      setBlockHours(agendaOff[2].horasOff)
    }
    if (dayjs(selectedDay).$W == 3) {
      setBlockHours(agendaOff[3].horasOff)
    }
    if (dayjs(selectedDay).$W == 4) {
      setBlockHours(agendaOff[4].horasOff)
    }
    if (dayjs(selectedDay).$W == 5) {
      setBlockHours(agendaOff[5].horasOff)
    }
    if (dayjs(selectedDay).$W == 6) {
      setBlockHours(agendaOff[6].horasOff)
    }
    setSinHoras(false);
    setHorasDisponiblesInicio(["09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45", "18:00"]);
    setBorbot([""]);
    setApa("btn btn-info ");
    setTime("Hora");
    setStartDate(selectedDay);
    setDate(`${dayjs(selectedDay).format("YYYY-MM-DD")}T`);
    filterData(`${dayjs(selectedDay).format("YYYY-MM-DD")}T`);
    setFecha(`${dayjs(selectedDay).format("DD/MM/YYYY")}T`);
  };

  const escogeHora = (horario) => {
    setTime(horario);
    let x = date.slice(0, 11);
    let y = fecha.slice(0, 11);
    setDate(`${x}${horario}`);
    setFecha(`${y}  ${horario} hrs`);
  };

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setData(schedule);
    else {
      const filteredData = schedule.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : String(item[key]).toLowerCase().includes(lowercasedValue)
        );
      });
      setData(filteredData);
    }
  };

  const verify = () => {
    data.map((info) => borbot.push(info.time));
    const disponibles = horasDisponiblesInicio.filter((item) => !borbot.includes(item));

    if (disponibles.length === 0) {
      setHorasDisponiblesInicio(disponibles);
      setSinHoras(true);
    } else {
      setHorasDisponiblesInicio(disponibles);
    }
  };

  const udates = () => {
    let usdates = [];
    usrdates.map((info) => usdates.push(info.date));
    let year = usdates.map((v) => parseInt(v.slice(0, 4)));
    let month = usdates.map((v) => parseInt(v.slice(5, 7)));
    let day = usdates.map((v) => parseInt(v.slice(8, 10)));
    let datos = [];

    for (var i = 0; i < year.length; i++) {
      datos.push(new Date(`${year[i]},${month[i]},${day[i]}`));
      setUsdat(datos);
    }
  };

  const saveDate = () => {
    if (
      doctor === user1.id ||
      date === "Fecha" ||
      time === "Hora" ||
      date === "Fecha07:00" || date === "Fecha07:30" ||
      date === "Fecha08:00" || date === "Fecha08:30" ||
      date === "Fecha09:00" || date === "Fecha09:30" ||
      date === "Fecha10:00" || date === "Fecha10:30" ||
      date === "Fecha11:00" || date === "Fecha11:30" ||
      date === "Fecha12:00" || date === "Fecha12:30" ||
      date === "Fecha13:00" || date === "Fecha13:30" ||
      date === "Fecha14:00" || date === "Fecha14:30" ||
      date === "Fecha15:00" || date === "Fecha15:30" ||
      date === "Fecha16:00" || date === "Fecha16:30" ||
      date === "Fecha17:00" || date === "Fecha17:30" ||
      date === "Fecha18:00" || date === "Fecha18:30" ||
      date === "Fecha19:00" || date === "Fecha19:30" ||
      date === "Fecha20:00"
    ) {
      return Swal.fire({
        allowEscapeKey: true,
        icon: "info",
        title: "Informacion incompleta",
        text: "Escoge todos los campos",
        confirmButtonText: `Ok`,
        timerProgressBar: true,
      });
    }
    Swal.fire({
      title: `Tu turno con el/la Dr. ${doctorName} ${doctorLname}, para el ${fecha.replace(
        "T",
        " a las"
      )}`,
      text: "Es correcta la informacion ?",
      icon: "question",
      showCancelButton: true,
      reverseButtons: true,
      allowEscapeKey: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Agendar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(SCHUDULEPOST, jsonSend, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then(() => {
            axios.patch(`${process.env.REACT_APP_API}editusers/${user1.id}/${user1.id}`, { pacienteExistente: true }, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
              .then(() => {
                Swal.fire({
                  allowEscapeKey: true,
                  icon: "success",
                  title: "Listo! tu turno esta agendado",
                  confirmButtonText: `Ok`,
                  timer: 1000,
                  timerProgressBar: true,
                })
                  .then(() => {
                    window.location.reload();
                  });
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => {
            let message = error.response.data.message;
            // swal que al final haga windows.location.reload


            Swal.fire({
              allowEscapeKey: true,
              icon: "error",
              title: "Oops...",
              text: "Lo sentimos esta acción no se pudo completar " + message,
            })
              .then(() => {
                window.location.reload();
              }
              );
            // console.log(error);
          });
      }
    });
  };

  const isWeekday = (date) => {
    const day = getDay(date);
    return !daysOff.some((dayOff) => {
      if (day == dayOff) {
        return true;
      }
      return false;
    });
  };

  const checkCitasDoctor = (citasUsuario, doc) => {
    let total = []
    citasUsuario.filter((item) => {
      if (item.profesional[0]._id == doc && dayjs(`${item.date.split('T')[0]}${item.time}`) > dayjs().set('hour', 23).set('minute', 59).set('second', 59)) {
        total.push(item.profesional[0]._id)
      }
    })
    return total.length
  }

  return (
    <>
      {isAuth ? (
        user1.role === "user" ? (
          <>
            <div>
              {
                primeraCita ? // ya tuve mi primer cita true
                  citasTotalesUser >= 4 ?
                    <Button disabled variant="primary">Has alcanzado el limite de turnos por paciente</Button>
                    :
                    // aqui le ponemos el limite de citas totales por usuario de todos los profesioanles
                    <Button variant="primary" onClick={handleShow}>Agendar turno</Button>
                  : pacienteExistente ? // ya tuve mi primer cita false
                    <Button disabled variant="primary">Has alcanzado el limite de turnos por paciente</Button>
                    :
                    <Button variant="primary" onClick={handleShow}>Agendar turno</Button>
              }
              {!formulario ? (
                <Button className="" href="/formulario">
                  Formulario
                </Button>
              ) : null}
              <Modal show={show} size="sm" onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Turno</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Col xs={6}>
                      <Form.Group>
                        <DropdownButton variant="outline-info" title={titleedbot}>
                          {
                            doctors.map((doctor, i) => (
                              <div key={i}>
                                {
                                  edadPaciente <= doctor.limiteEdad && edadPaciente >= doctor.limiteEdadInferior ?
                                    <Dropdown.Item
                                      onClick={() => {
                                        setLoading(true);
                                        setSchedule([]);
                                        resultadoHorasPacientesNuevos = []
                                        resultadoHorasPacientesViejos = []
                                        setHighlightWithRanges([{ "react-datepicker__day--highlighted-custom-2": [], }, { "react-datepicker__day--highlighted-custom-1": [], },])
                                        setDoctor(doctor._id);
                                        setHorasOff(doctor.agendaOff);
                                        setDoctorName(doctor.nombre);
                                        setDoctorLname(doctor.apellido);
                                        setTime("Hora");
                                        setDate("Fecha");
                                        setStartDate();
                                        setAgendaOff(doctor.agendaOff);
                                        setLimiteCitasObraSemanal(doctor.obraSocialAtendida.filter((o) => o.nombre == user1.obraSocial)[0].limite)
                                      }}
                                      key={i}
                                    >
                                      <h4 className="alineacion">
                                        {doctor.nombre} {doctor.apellido}<br />{`${doctor.especialidad}`}
                                      </h4>
                                    </Dropdown.Item>
                                    : null
                                }
                              </div>
                            ))
                          }
                        </DropdownButton>
                      </Form.Group>
                    </Col>
                    <br />
                    <Col>
                      <Form.Group>
                        {
                          doctor === "" ? null :
                            loading ? null :

                              <DatePicker
                                calendarStartDay={0}
                                locale="es"
                                todayButton="Hoy"
                                highlightDates={highlightWithRanges}
                                includeDateIntervals={[{ start: new Date(), end: new Date(dayjs().add(90, 'day')) },]}
                                className="picker"
                                placeholderText="Selecciona la Fecha"
                                selected={startDate}
                                excludeDates={resultadoFinalDiasBloqueados}
                                dateFormat="dd/MM/yyyy"
                                filterDate={isWeekday}
                                onChange={(date) => diaSeleccionado(date)}
                              />
                        }
                      </Form.Group>
                    </Col>
                    <br />
                    <Col>
                      {
                        loading ? null :
                          fecha === "" ? null :
                            <>
                              <Form.Group>
                                <DropdownButton variant="outline-info" title={time}>
                                  {sinHoras ? (
                                    <>
                                      <h6 className="CitaSeleccionada sinhoras">
                                        Ups! no hay horas disponibles.<br></br>Escoge otro
                                        dia por favor
                                      </h6>
                                    </>
                                  ) : (
                                    <>
                                      {pacienteExistente && primeraCita ? // estas son las horas de los pacientes viejos
                                        resultadoHorasPacientesViejos.length == 0 ?
                                          <>

                                            <h6 className="CitaSeleccionada sinhoras">
                                              Ups! no hay horas disponibles.<br></br>Escoge otro
                                              dia por favor
                                            </h6>
                                          </>
                                          :
                                          resultadoHorasPacientesViejos.map((hora, i) => (
                                            <Dropdown.Item
                                              onClick={() => escogeHora(hora, i)}
                                              key={i}
                                              className={apa}
                                            >
                                              <h4 className="alineacion">{hora}</h4>
                                            </Dropdown.Item>
                                          ))
                                        :
                                        resultadoHorasPacientesNuevos.length == 0 ?
                                          <>
                                            <h6 className="CitaSeleccionada sinhoras">
                                              Ups! no hay horas disponibles.<br></br>Escoge otro
                                              dia por favor
                                            </h6>
                                          </>
                                          :
                                          resultadoHorasPacientesNuevos.map((hora, i) => ( // estos son las horas de los pacientes viejos
                                            <Dropdown.Item
                                              onClick={() => escogeHora(hora, i)}
                                              key={i}
                                              className={apa}
                                            >
                                              <h4 className="alineacion">{hora}</h4>
                                            </Dropdown.Item>
                                          ))}
                                    </>
                                  )}
                                </DropdownButton>
                              </Form.Group>
                              <br />
                            </>
                      }
                    </Col>
                    <Form.Group>
                      <textarea
                        className="note"
                        placeholder={note}
                        rows="3"
                        onChange={(e) => {
                          setNote(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    type="submit"
                    onClick={() => {
                      saveDate();
                    }}
                    className="btn btn-info"
                  >
                    Siguiente
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
        ) : undefined
      ) : undefined}
    </>
  );
}

export default Apointment;
